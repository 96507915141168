<template>
  <div class="app-container prompt-text">
    <el-form
        v-if="!state.joinRoom"
        class="join-form"
        ref="dataForm"
        label-position="right"
        status-icon
        :rules="rules"
        label-width="100px"
        :model="tempForm"
    >
      <el-form-item prop="domain" label="服务地址">
        <el-input v-model.trim="tempForm.domain" placeholder="请输入服务地址" clearable />
      </el-form-item>
      <el-form-item prop="username" label="用户名">
        <el-input v-model.trim="tempForm.username" placeholder="请输入用户名" clearable />
      </el-form-item>
      <el-form-item prop="appId" label="appId">
        <el-input v-model.trim="tempForm.appId" placeholder="请输入App ID" clearable />
      </el-form-item>
      <el-form-item prop="appSecret" label="appSecret">
        <el-input v-model.trim="tempForm.appSecret" type="password" placeholder="请输入App SecretKey" clearable />
      </el-form-item>
      <el-form-item prop="roomId" label="房间号">
        <el-input v-model.trim="tempForm.roomId" placeholder="请输入房间号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button v-if="!state.disabled" type="primary" @click="enterRoom(dataForm)">{{tempForm.roomId ? '加入房间' : '创建房间'}}</el-button>
        <el-button disabled v-else type="primary" icon="Loading">{{tempForm.roomId ? '加入房间' : '创建房间'}}</el-button>
      </el-form-item>
    </el-form>
    <div v-else>
      <div>"{{tempForm.username}}"加入房间"{{tempForm.roomId}}"成功</div>
      <div class="prompt-text join-room"><el-button type="primary" @click="leaveRoom">离开房间</el-button></div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, onBeforeMount, onBeforeUnmount, onMounted } from 'vue'
import Server from '@/utils/server'
import {ElMessage, FormInstance, FormRules} from 'element-plus'
import MRTC from '@/mrtc/mrtc.esm'
import { createRandomNo, getDevices, debounce } from '@/utils'

// 定义表单
const dataForm = ref<FormInstance>(null)

// 表单输入字段
const tempForm = reactive({
  ...Server,
  userId: createRandomNo(6),
  username: `DoryGuest-${createRandomNo(4)}`, // 用户名称
  roomId: createRandomNo(9), // 房间ID
  mode: 'rtc',
  character: 'audience'
})

const state = reactive(({
  sdk: null,
  joinRoom: false,
  disabled: false
}))

const rules = reactive({
  domain: [
    { required: true, message: '请输入服务地址', trigger: 'blur' }
  ],
  username: [
    { required: true, message: '请输入用户昵称', trigger: 'blur' }
  ],
  appId: [
    { required: true, message: '请输入App ID', trigger: 'blur' }
  ],
  appSecret: [
    { required: true, message: '请输入App SecretKey', trigger: 'blur' }
  ]
})

const joinDory = async (params) => {
  try {
    // 初始化sdk
    state.sdk = await MRTC.init({
      displayName: params.username,
      roomId: params.roomId,
      userId: params.userId,
      mode: params.mode,
      userRole: params.character,
      applicationId: params.appId,
      applicationSecretKey: params.appSecret,
      host: params.domain,
      streamLevel: params.streamLevel || 3,
      debugLevel: 1
    })

    // 如果 sdk 初始化异常
    if (state.sdk.status === 'error') {
      state.disabled = false
      throw new Error(state.sdk.reason)
    }

    // 初始化成功调用加入房间方法
    state.sdk.joinRoom()

    // 订阅->加入房间成功
    state.sdk.on('room-joined', (peers) => {
      state.joinRoom = true
      state.disabled = false
      console.log('peers', peers)
      // todo:加入房间成功的事件监听，peers为房间中远端用户列表，可以利用该列表统计房间人数，查看房间人员基本信息等等，根据实际情况合理利用
    })
    // 订阅->加入房间失败
    state.sdk.on('room-joinFailed', (result) => {
      // todo： 加入房间失败时做一些处理
      ElMessage({
        message: `加入房间失败: ${result}`,
        type: 'error',
        duration: 5 * 1000
      })
    })
    // 订阅-> 检测到新用户加入
    state.sdk.on('peer-add', (peer) => {
      console.log('peer', peer)
      // todo：peer为进入房间的用户，可将peer加到peers中得到最新远端用户
    })
    // 订阅->检测到用户离开
    state.sdk.on('peer-leave', (peerId) => {
      console.log('peerId', peerId)
      // todo：peerId为离开房间的用户的id，可在peers中删除id为“peerId"的用户得到最新远端用户
    })
    // 订阅-> 收到音视频流
    state.sdk.on('stream-add', (params, innerParams) => {
      // 本端开启的音频流
      if (params.isLocal) {
        // todo：可以将流存起来用于播放
        // 远端开启的远端流
      } else {
        // todo：可以将流存起来用于播放
      }
    })

    // 订阅-> 收到远端关闭音视频流
    state.sdk.on('stream-removed', (params, innerParams) => {
      // 本端关闭音频流
      if (params.isLocal) {
        // todo：在存起来的流中删除该流
        // 远端
      } else {
        // todo：在存起来的流中删除该流
      }
    })
    // 订阅-> 房间信令连接错误
    state.sdk.on('room-connect-error', (message) => {
      // console.log(message)
    })
    // 订阅-> 房间信令连接成功
    state.sdk.on('room-connect', () => {})
    // 订阅-> 房间信令断连
    state.sdk.on('room-disconnect', () => {})
  } catch (e) {
    console.log(e)
    throw e
  }
}

// 进入房间按钮
const enterRoom = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid) => {
    if (valid) {
      tempForm.roomId = tempForm.roomId || createRandomNo(9) // roomId为空时，设置随机9为数
      state.disabled = true
      joinDory(tempForm)
    }
  })
}

//  离开房间
const leaveRoom = async () => {
  console.log('离开房间')
  if (state.sdk) {
    await state.sdk.leaveRoom()
    state.joinRoom = false
    state.sdk = null
  }
}
// 获取设备
const checkMediaDevicesValid = async () => {
  if (!MRTC.isSupportWebRTC()) {
    ElMessage({
      message: `检测 WebRTC 支持失败。原因：您当前使用的浏览器不支持 WebRTC，建议使用 Chrome 或 Firefox 浏览器\``,
      type: 'error',
      duration: 0,
      showClose: true
    })
    return
  }
  try {
    const devices = await getDevices()
    console.log('设备', devices)
  } catch (error) {
    ElMessage({
      message: `检测摄像头、麦克风失败原因：${error.name}->${error.message}`,
      type: 'error',
      duration: 0,
      showClose: true
    })
  }
}

// 挂载完成前
onBeforeMount(() => {
  // 检测设备
  checkMediaDevicesValid()
})

//  销毁组件前
onBeforeUnmount(() => {
  leaveRoom() // 离开房间
})

// 组件挂载完成
onMounted(() => {
  // 主要监听浏览器刷新，当刷新时离开房间
  window.addEventListener('beforeunload', () => {
    leaveRoom() // 离开房间
  })
})


</script>

<style>
.join-form{
  width: 700px;
}
.prompt-text.join-room{
  height: 50px;
}
</style>
