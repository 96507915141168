// 仅限内部测试用，请勿外传
/**
 * domain socket服务端地址
 * appId 应用Id
 * appSecret 项目密钥
 * streamLevel 视频清晰度
 */
const Server = {
    "domain" : "rtc.migu.cn",
    "appId" : "766298192982573056",
    "appSecret" : "ltx4tgo8inalm86ethmp0or32l64jyuftxmd4k2pheqdbunv3t7fa58y118sw908sej0lgzs47zjy4cdtluo7cpmum6lnv0y02l0omy90hy7dyf9miq1xsn2el67fity",
    "streamLevel": 3
}
export default Server
