import { createRouter, createWebHistory } from 'vue-router'
import baseJoin from '@/pages/baseJoin/index.vue'

export const basicRoutes = [
  {
    path: '/device-detection',
    component: () => import(/* webpackChunkName: "about" */ '@/pages/deviceDetection/index.vue'),
    meta: { title: '音视频设备检查', content: '提供了通话前设备检测和通话中设备切换的功能。' }
  },
  {
    path: '/base-join',
    component: () => import(/* webpackChunkName: "about" */ '@/pages/baseJoin/index.vue'),
    meta: { title: '初始化sdk，进入房间', content: '提供初始化sdk，加入房间的功能。'}
  },
  {
    path: '/basic-all',
    component: () => import(/* webpackChunkName: "about" */ '@/pages/basicCall/index.vue'),
    meta: { title: '音视频通话', content: '提供了音视频通话的功能。' }
  },
  {
    path: '/screen-Share',
    component: () => import(/* webpackChunkName: "about" */ '@/pages/screenShare/index.vue'),
    meta: { title: '共享屏幕', content: '提供了屏幕共享的视频通话功能。' }
  },
  {
    path: '/custom-Stream',
    component: () => import(/* webpackChunkName: "about" */ '@/pages/customStream/index.vue'),
    meta: { title: '自定义音视频推流', content: '提供了自定义音视频推流的功能。' }
  },
  {
    path: '/screen-shot',
    component: () => import(/* webpackChunkName: "about" */ '@/pages/screenShot/index.vue'),
    meta: { title: '屏幕录制/截屏', content: '提供了录制屏幕与截取屏幕的功能。' }
  },
  {
    path: '/mix-stream',
    component: () => import(/* webpackChunkName: "about" */ '@/pages/mixStream/index.vue'),
    meta: { title: '混流', content: '提供开启大小流的视频通话的功能。' }
  }
]

export const routes = [
  { path: '/',  meta: { title: 'Dory Web Api Examples' }, component: () => import(/* webpackChunkName: "about" */ '@/layout/index.vue') },
  ...basicRoutes
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
