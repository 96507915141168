<template>
<div style="width: 100%; height: 100%;">
  <header class="header prompt-text">{{headerText}}</header>
  <div style="height: calc(100% - 50px);">
    <router-view/>
  </div>
  <!-- 在首页则不展示首页按钮 -->
  <div v-if="!isHome" style="position: fixed;right: 20px;bottom: 20px;">
    <el-button
        style="width: 40px;height: 40px;"
        title="回到首页"
        type="primary"
        icon="RefreshLeft"
        circle
        @click="goHome" />
  </div>
</div>

</template>

<script setup>
import { watch, ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

// 是否在首页
const isHome = ref(true)

// 头部显示信息
const headerText = ref('')

// 回到首页
const goHome = () => {
  router.push({
    path: '/',
  })
}

watch(() => router.currentRoute.value, (newValue, oldValue) => {
  console.log('newValuevv', newValue)
  // 当路由发生变化时，判断当前路由是否为首页，如果是首页则不展示回到首页按钮，是其他路由则显示回到首页路由
  // isHome.value = newValue.path ===  PUBLIC_PATH+'/'
  isHome.value = newValue.path ===  '/'
  // 获取头部显示信息
  headerText.value = newValue.meta.title
 },{ immediate: true })
</script>

<style lang="scss">
</style>
