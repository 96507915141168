//  随机产生位数
export function createRandomNo (length) {
  return Math.random().toString().slice(-length) + ''
}

/**
     * 获取+8区中国时间戳，和 服务器 保持同步
     */
export function getChinaTimeStamp() {
  const localDate = new Date()
  const timezoneOffset = localDate.getTimezoneOffset()
  const localTimeStamp = localDate.getTime()
  const utcTimestamp = localTimeStamp + timezoneOffset * 60 * 1000
  const chinaTimeStamp = utcTimestamp + 60 * 60 * 1000 * 8
  return chinaTimeStamp

}

// 获取设备
export function getDevices () {
  return new Promise((resolve, reject) => {
      navigator.mediaDevices.getUserMedia(
          // 此处参数形式较多，可参照webrtc Api，地址：https://developer.mozilla.org/zh-CN/docs/Web/API/MediaDevices/getUserMedia
          {
              audio: true,
              video: true
          }
      ).then((stream) => {
        stream.getTracks().forEach((track)=>{
          track && track.stop()
        })
          navigator.mediaDevices.enumerateDevices().then((devices) => {
              const videoArr = []
              const audioArr = []
              const speakerArr = []

              for (const device of devices) {
                  if (device.kind === 'videoinput') {
                      videoArr.push({
                          label: device.label ? device.label : device.deviceId,
                          id: device.deviceId
                      })
                  }
                  if (device.kind === 'audioinput') {
                      audioArr.push({
                          label: device.label ? device.label : device.deviceId,
                          id: device.deviceId
                      })
                  }
                  if (device.kind === 'audiooutput') {
                    speakerArr.push({
                      label: device.label ? device.label : device.deviceId,
                      id: device.deviceId
                    })
                  }
              }
              resolve({
                videoArr,
                audioArr,
                speakerArr
              })
          })
      }).catch(err => {
        reject(err)
    })
  })
}

 // 防抖
export function debounce(fun, delay) {
  let t=''
  return  (args) => {
    let that
    let _args = args
    clearTimeout(t)
    t= setTimeout(function () {
      fun.call(that, _args)
    }, delay)
  }
}

// 节流函数
export function throttle(fn, delay = 1000) {
  var previous = 0;
  // 使用闭包返回一个函数并且用到闭包函数外面的变量previous
  return function() {
      var _this = this;
      var args = arguments;
      var now = new Date();
      if(now - previous > delay) {
          fn.apply(_this, args);
          previous = now;
      }
  }
}

// 时间戳转时间
export function timestampToDateTime(timestamp) {
  const date = new Date(timestamp)
  const Y = date.getFullYear()
  const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  const  h = date.getHours() < 10 ? '0'+date.getHours() : date.getHours()
  const  m = date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()
  const  s = date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds()
  return Y+'-'+M+'-'+D+' '+h+':'+m+':'+s
}
